<template>
  <div class="container">
    <el-card class="box-card">
      <div class="query-box">
        <div class="query-item">
          <div>礼物名称：</div>
          <div>
            <el-input v-model="query.giftName" placeholder="请输入礼物名称" size="small"></el-input>
          </div>
        </div>
        <div class="query-btn">
          <el-button type="primary" size="small" @click="getData" style="width: 90px">搜索</el-button>
        </div>
      </div>
      <div class="cz-btn-box">
        <el-button type="primary" size="small" @click="giftDialogShow = true">添加礼物</el-button>
      </div>
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="礼物名称" prop="giftName"></el-table-column>
        <el-table-column align="center" label="礼物图标">
          <template slot-scope="scope">
            <el-image style="width: 30px" :previewSrcList="[scope.row.giftImg]" :src="scope.row.giftImg"
                      fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="金豆价格" prop="goldAmount"></el-table-column>
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'" disable-transitions>
              {{ scope.row.status == 0 ? '禁用' : '启用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)" icon="el-icon-edit" size="mini">修改</el-button>
            <el-button type="danger" @click="remove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--  添加/编辑-对话框  -->
    <el-dialog top="10vh" :title="form.id ? '修改礼物' : '添加礼物'" :visible.sync="giftDialogShow" width="40%"
               :before-close="handleClose">
      <el-form :model="form" ref="form" label-width="120px" :rules="rules">
        <el-form-item label="礼物名称:" prop="giftName">
          <el-input v-model="form.giftName" placeholder="请输入礼物名称"
                    autocomplete="off" @focus="clearValidate('giftName')"></el-input>
        </el-form-item>
        <el-form-item label="礼物图标:" prop="giftImg">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="successImages">
            <el-image v-if="form.giftImg" fit="fill" :src="form.giftImg" class="avatar"
                      style="width: 120px;height: 120px">
            </el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="金币数:" prop="goldAmount">
          <el-input v-model="form.goldAmount" placeholder="请输入金币数" type="number"
                    autocomplete="off" @focus="clearValidate('goldAmount')"></el-input>
        </el-form-item>
        <el-form-item label="状态:" style="margin-top:10px;">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {liveGiftList, liveGiftDel, liveGiftAdd, liveGiftSet} from '@/apis/live'

export default {
  name: "giftList",
  data() {
    return {
      tableData: [],
      query: {
        giftName: ''
      },
      total: null,
      loading: false,
      giftDialogShow: false,
      form: {
        giftName: '',
        giftImg: '',
        goldAmount: null,
        status: 1
      },
      rules: {
        goldAmount: [{required: true, message: '金币数不能为空', trigger: ['blur', 'change']}],
        giftName: [{required: true, message: '请输入礼物名称', trigger: ['blur', 'change']}],
        giftImg: [{required: true, message: '请上传礼物图标', trigger: ['blur', 'change']}],
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const resp = await liveGiftList(this.query)
      this.tableData = resp.data
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    edit(row) {
      this.giftDialogShow = true;
      this.form = {...row}
      delete this.form.createTime;
    },
    remove(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await liveGiftDel({id});
        this.$message({message: "删除成功", type: "success"});
        this.getData();
      })
    },
    add() {
      this.$refs.form.validate(async (val) => {
        if (!val) return;
        this.form.id ? await liveGiftSet(this.form) : await liveGiftAdd(this.form)
        this.$message({
          message: this.form.id ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.giftDialogShow = false;
        this.getData();
        this.clearCen();
      });
    },
    handleClose() {
      this.giftDialogShow = false;
      this.clearCen();
    },
    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.form.status = 1
      delete this.form.id; // 删除对象里面的id
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.clearValidate('giftImg')
      this.form.giftImg = response.data;
    },
  }
}
</script>

<style lang="less" scoped>
.query-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  font-size: .9em;

  .query-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 40px;

  }

  .query-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

  }
}

.cz-btn-box {
  margin-top: 30px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 100px;
  border: 1px dashed #999;
}

.el-form-item {
  width: 70%;
}
</style>